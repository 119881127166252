@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@700&display=swap');
.popuOverlay{
    position: fixed;
    background: #6b6b6b47;
    z-index: 9999;
    top: 0%;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display:flex;
    justify-content:center;
    align-items:center
}
.popupContainer{
    /* background: red;
    width: 56%;
    height: max-content;
    display: flex;
    position: relative;
    margin: 0 auto;
    top: 18%;
    border-radius: 30px;
    padding: 25px; */


    width: 100%;
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background:pink; */
    /* background-image: url("https://images.unsplash.com/photo-1720048169707-a32d6dfca0b3"); */
    background-image: url("https://foyforyou.com/assets/images/login-mobile-compressed.jpeg");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    border-radius: 14px;
    margin:.5rem;
}

.popupContainer input{
    font-family: 'Red Hat Text',sans-serif;
}
.popup-mid-Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    width:100%;
    height:100%;
    position:relative

}
.popup-mid-Container .popup-innerContainer{
   
 padding: 10px 43px 40px;
    border-radius: 30px;
    background-color: #f6f6f6;
    width: 70%;
    /* margin: auto; */
}
.foyImg{
    /* transform: translate(0,-34%); */
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.foyImg img{
    max-height: 70px;
    /* margin: 0 auto; */
        /* max-width: 100%; */
}
.otpPopup{
  display:flex;
    flex-direction:column;
    align-items:center;
    position:relative;
}
.headparaContainer{
      display:flex;
    flex-direction:column;
    align-items:center;
    gap:7px;
    padding:16px 0;
}

.contentpara{
    display:flex;
    flex-direction:column;
    align-items:center;
    gap:16px;
    margin-top:13px
}
/* .foyImg img.crosspopup{
    margin: unset;
    max-height: 15px;
} */

.crosspopup{
    position: absolute;
    right: 15px;
    top: 11px;
    opacity: 1;
    /* padding: 0 20px; */
    background: unset;
    display: flex;

    box-sizing: content-box;
    width: 1em;
    height: 1em;
    /* padding: .25em; */
    color: #000;
    border: 0;
    border-radius: .375rem;
    
}
.crosspopup:hover{
    color: #000;
    text-decoration: none;
    opacity: .75;
}
.boldPara{

    /* text-transform: uppercase; */
    /* font-weight: 700;
    font-size: 12px;
    color: #000;
    font-family: 'Red Hat Text',sans-serif;
    line-height: 1.5;
    letter-spacing: normal;

    font-weight: 700;
    color: #000;
    text-align: center;
    margin-bottom: 8px; */

    font-weight: 900;
    color: #000;
    text-align: center;
    font-size: 14px;
}
.lightPara{
    /* font-weight: 700;
    font-size: 12px;
    color: #686868;
    padding: 7px 0;
    font-family: 'Red Hat Text',sans-serif;
    line-height: 1.5;
    letter-spacing: normal;
    max-width: 90%; */
    font-weight: 700;
    font-size: 12px;
    color: #a9a7a7;
}

.otplightPara{
    
    font-size: 14px;
 
}
.divider{
    color: #686868;
}
.head_otp_container{
    width:100%;
    display:flex;
    flex-direction: column;
}

.otp_container, .phon_numb{
    font-weight: 700;
    font-size: 13px;
    color: #333;
    padding: 0;
    border: unset;
    background: #ffffff;
    text-align: center;
    display: flex;
    width:100%;
}

.otp_container input, .phon_numb input{
 text-align: center;

   font-weight: 700;
    font-size: 13px;
    color: #333;
    padding: 0;
    border: unset;
    background: #ffffff;
    text-align: center;
    display: flex;
    min-height: 38px;
    line-height: 38px;
    outline: none;
    width:100%
}
.phone-number{
   
}
.phon_numb span{
    padding: 0px 5px;
}
input#phonenumber{
    padding:0px 5px;
    width: 80%;
    border: none;
    font-weight: 700;
    color: #000;
    background: transparent;
}
input#phonenumber::placeholder{
    font-weight: 700;
    color: #5e5e5e;
    font-family: 'Red Hat Text',sans-serif;
    line-height: 1.5;
    letter-spacing: normal;
   
}
input#phonenumber:focus,input#phonenumber:active{
    border: none;
    outline: none;
}
.btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
}
.btn{
    /* height: 31px; */
    width: 70%;
    padding:8px 0;
    /* margin: 15px 0px; */
    /* margin: 10px 0 0; */
    background: #5e5e5e;
    border: 1px solid #DCDADA;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:6px;
    color: white;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Red Hat Text',sans-serif;
    line-height: 1.5;
    letter-spacing: normal;
}
.btn-slate{
     background: #5e5e5e;
}
.btn-dark{
 background: black;
}
.popcrossbtn{
    border: 1px solid #111;
    width: 17px;
    height: max-content;
    font-size: 12px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    animation: c;
    align-items: center;
}
.acc_input{
    font-weight: 700;
    font-size: 13px;
    color: #333;
    border: unset;
    background: transparent;
    padding: 0 10px;
    border: 1px solid #000;
    border-radius: 40px;
    margin-top: 15px;
    padding: 10px;
    width:90%;
}
.acc_input#gender{
    width: 100%;
    color: #686868;
}

.acc_input:focus,.acc_input:active{
    outline: unset;
}
/* input#EnterOtp{
    border: 1px solid #111;
    padding: 10px;
    border-radius: 19px;
    font-size: 13px;
    font-weight: 700;
    margin: 15px 0;
    color: #000;
    width: 90%;
} */
input#EnterOtp:focus,input#EnterOtp:active{
    border: 1px solid #111;
    outline: none;
}
input#EnterOtp::placeholder{
    font-weight: 700;
    color: #5e5e5e;
    font-family: 'Red Hat Text',sans-serif;
    line-height: 1.5;
    letter-spacing: normal;
}
.changePhn{
    border-bottom:1px solid ;
    background: linear-gradient(92.22deg, #FF8210 -11.24%, #FF55BC 41.08%, #C678F4 104.23%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-bottom: 1px solid;
    border-color: #FF55BC;
    cursor: pointer;
}

.otpPara{

    display: flex;
    justify-content:center;
    font-weight: 500;
    font-size: 12px;
    color: black;
    /* padding: 7px 0; */
    /* font-family: 'Red Hat Text',sans-serif; */
    line-height: 1.5;
    letter-spacing: normal;

    margin-top:30px;
    margin-bottom:10px
}
.otpPara a{
    /* text-decoration: none; */
    color: black;
}
.otperrorMsg{
    position: absolute;
    color: #fff;
    z-index: 9999;
    top: 2%;
    background: #bd362f;
    width: 56%;
    box-shadow: 0 0 12px #999;
    border-radius: 3px;
    background-position: 15px center;
    overflow: hidden;
    margin: 0 0 6px;
    word-wrap: break-word;
    padding: 15px 15px 15px 50px;
    letter-spacing: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    right:10px;
}
button.closeErrorMsg {
    background: unset;
    border: unset;
    color: #fff;
    font-weight: 700;
    font-size: 24px;
}
.errorMsg{
    color: red;
    font-size: 13px;
    letter-spacing: normal;
    /* font-weight: 600; */
}
.opacityBtn{
    opacity: .5;
    pointer-events: none;
}

.optVerificationError{
    background-color: #f790908a;
    text-align: center;
    width: 66%;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    font-size: 17px;
    color: darkred;
    /* position: absolute;
    top:0px; */
    
}

.thankYou-container{
    display: flex;
    flex-direction:column;
    align-items: center;
    gap:20px;
    font-weight:500;
    font-size:14px;
    margin-top:3px;
  
   
}
.boldThankPara{
font-weight:900;
}

.popUpspinner {
  animation: spinner 2s infinite linear;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
