.ProductContent {
  /* padding-top: 15px; */
}
.color-grid label > div {
  width: 40px;
  height: 40px;
  /* background: red; */
  border-radius: 3.815px;
  box-shadow: rgb(22 22 22 / 28%) 0px 4px 12px;
}
.color-grid input {
  display: none;
}
.prod_title_grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #111;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.brand_name {
  font-size: 25px;
}
.comparePrice.procomparePrice {
  font-size: 15px;
  padding: 0 7px;
}
.product_name {
  font-size: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  
}
.wishlistIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  background: #ff55bc;
  border: 1px solid #ff55bc;
  border-radius: 5px;
  height: auto;
}
.buy_now {
  height: 100%;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 12px 35px;
  font-size: 16px;
  color: #ff55bc;
  font-weight: 700;
  text-transform: uppercase;
}
/* [title="01-LUSTY PINK"],[title="02-DEEP DAWN"]{
  display: none;
} */
.ymkComponents {
  /* height: 475px; */
  margin-bottom: 1rem;
  /* background-color: #000; */
}
.color-grid {
  overflow-x: scroll;
}
.main_prod_grid {
  width: 100%;
}
.add_to_cart {
  background: #ff55bc;
  border: 1px solid #ff55bc;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}
.toneText {
  width: 30%;
  background: #fff;
  color: #ff55bc;
  padding: 10px;
  border-radius: 6.386px;
  border: 1.277px solid #ff55bc;
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
  box-shadow: 10.21687px 10.21687px 20.43373px 0px rgba(188, 187, 187, 0.06),
    -10.21687px -10.21687px 20.43373px 0px rgba(167, 167, 167, 0.03);
}
.toneText.firstTone {
  background-color: #ff55bc;
  color: #fff;
  border: 1.277px solid #ff55bc;
}
.filter_name {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
.PriceContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.TitleWidth {
  width: 68%;
}
.PriceContent div {
  font-family: "RedHatText";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  color: #000;
  text-align: center;
}
.prod_title_grid .discount {
  font-size: 17px;
}
.btnProduct {
  /* justify-content: space-around; */
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  height: 76px;
justify-content:space-around;
  align-items: center;
  border-top: 1px solid #111;
  z-index: 20;
}
.btnProduct button {
  height: 50px;
}
.buynowWrapper {
  width: 40%;
}
.buynowWrapper button {
  width: 100%;
}
.popup_atc_overlay {
  position: fixed;
  background: #00000040;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
}
.popAtc {
  position: absolute;
  /* z-index:99; */
  width: 70%;
  bottom: 10%;
  left: 10%;
  background: #fff;
  border-radius: 15px;
  padding: 25px 20px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: var(--FOY-White, #fff);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
}
.backArrow {
  padding: 0px 20px 0 0;
}
.popAtc p {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "HarmoniaSansProCyr";
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.brand_type {
  color: #ff55bc;
  font-size: 20px;
}
.varinatsShade label.borderShade {
  border: 1px solid #111;
  padding: 2px;
  border-radius: 4px;
}
.qtybar {
  border: 1px solid #000;
  border-radius: 3px;
  /* padding: 10px; */
  display: flex;
  justify-content: space-evenly;
  width: 75px;
  height: 33px;
  align-items: center;
  font-size: 26px;
}
.qtybar img {
  width: 14px;
}
.qtybar input {
  border: unset;
  width: 30%;
  text-align: center;
  /* padding-left: 11px; */
}
.varinatsShade {
  display: flex;
  padding:10px;
}
.varinatsShade > label {
  margin-right: 5px;
}
.card_notFound {
  text-align: center;
}
@media only screen and (max-width: 767.98px) {
  .brand_type {
    color: #ff55bc;
    font-size: 15px;
  }
  .btnProduct > div {
    /* margin-right: 5px; */
  }
  .wishlistIcon {
    padding: 8px 10px;
  }
  .toneText {
    font-size: 12px;
    padding: 7px;
  }
  .brand_name {
    font-size: 12px;
  }
  .product_name {
    font-size: 18px;
  }
  .filter_name {
    font-size: 9px;
  }
}
@media only screen and (max-width: 380px) {
  .add_to_cart {
    padding: 10px 18px;
    font-size: 13px;
  }
  .buy_now {
    padding: 10px 32px;
    font-size: 15px;
  }
  .btnProduct > div {
    height: 40px;
  }
}
@media only screen and (max-width: 380px) {
  .buy_now {
    padding: 10px 25px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 320px) {
  .buy_now {
    padding: 10px 13px;
    font-size: 14px;
  }
}

/* Similar Products */

.SimilarProductContainer {
}
