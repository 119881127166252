.productsWrapper{
    /* display: flex; */
    /* flex-wrap: wrap;  */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items:"start";   
    /* height: 116% !important; */
    /* height:calc(100% - 70px); */
    /* justify-content:space-evenly; */
    gap:1px
}
.productsWrapperScroll{
    overflow-y: scroll;

}

.Sticky{
  position: sticky;
  top: -25%
}
.Sticky1{
  position: sticky;
  top: 10vh;
  z-index: 10;
  height: 85vh !important;
}
.similiarProductContainer{
  /* position: absolute; */
    border-radius: 25px 25px 0 0;
    /* bottom: 45%; */
    background: rgba(235, 232, 232, 1);
    left: 0;
    right: 0;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    height: calc(100% - 60px);
    margin:0
  }
 
 

.recommendedWrapper{
    height:100%;
}

.HeadScroll{
    display:flex;
    justify-content:center;
    /* background:red; */
    padding:7px 0
}.HeadScroll .black_btn{
    width:60px;
    height:3px;
    border-radius:50px;
    background: #000;
}

@media only screen and (max-width: 380px) {

.productsWrapper{

    gap:1px
}
}