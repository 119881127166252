.headerComponents {
  border-radius: 8px;
  width: 92%;
  z-index: 99;
  padding: 10px 0;
  transition: 0.5s all;
  background-color: #fff;
}
.header.sticky {
  position: fixed;
  width: 80%;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
}
.header.sticky .headerComponents{
  transition: 0.5s all;
  background-color: #fff;
}

.layout {
  position: relative;
}
.headerOuter {
  width:85%;
  margin:0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(
    --nav-bar-colour,
    linear-gradient(90deg, #c678f4 1.64%, #ff55bc 50.99%, #ff8210 100%)
  );
  /* margin: 20px 0px; */
  box-shadow: 0 0 10px #0003;
  border-radius: 10px;
  padding: 20px 60px;
}
ul {
  margin: 0;
  padding: 0;
}
.social_outer{
  display: none;
 }
.headerLogoMenu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.32px;
  text-transform: uppercase;
  margin: 1rem;
  text-decoration: none;
}

.menuActions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuAction {
  display: flex;
  margin: 10px;
  justify-content: center;
  align-items: center;
}

/* n 26-10 */
.headerMenu ul {
  margin: 0;
  padding: 0;
  display: flex;
}

ul li {
  list-style: none;
}

.menuAction input {
  width: 100%;
  background-color: transparent;
  border: 1px solid #fff;
  font-size: 14px;
  font-weight: 700;
  background-color: #ef9a9a0d;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNNTA4LjkgNDc4LjdMMzYwLjEgMzMwYzI4LjItMzQuOCA0NS4yLTc5LjEgNDUuMi0xMjcuM0M0MDUuMyA5MC45IDMxNC40IDAgMjAyLjcgMFMwIDkwLjkgMCAyMDIuN3M5MC45IDIwMi43IDIwMi43IDIwMi43YzQ4LjIgMCA5Mi41LTE3IDEyNy4zLTQ1LjJsMTQ4LjcgMTQ4LjdjNC4yIDQuMiAxMC45IDQuMiAxNS4xIDBsMTUuMS0xNS4xYzQuMS00LjIgNC4xLTEwLjkgMC0xNS4xem0tMzA2LjItMTE2Yy04OC4yIDAtMTYwLTcxLjgtMTYwLTE2MHM3MS44LTE2MCAxNjAtMTYwIDE2MCA3MS44IDE2MCAxNjAtNzEuOCAxNjAtMTYwIDE2MHoiIGZpbGw9IiMzNDNmNDkiLz48L3N2Zz4=) !important;
  background-size: 15px !important;
  background-position-x: 15 !important;
  background-position-y: center !important;
  background-repeat: no-repeat !important;
  color: #000;
  line-height: normal;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 5px 0;
}
.cartIcon,.wishlist{
  position: relative;
}
.cart_counts,.wishlistCounts{
  background: #fff;
    color: #000;
    width: 13px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 63%;
  padding: 1px;
}
.headerMenu {
  padding: 0 30px;
}

.mobile {
  display: none;
}

.menu_cart {
  height: 100vh;
  transform: translate(-100%, 0);
  box-shadow: 30px 50px 100px #85888c;
  top: 76px;
}

.addMenu {
  visibility: visible;
  transform: translate(0);
  background: #fff;
  z-index: 999999;
}
.menu_cart {
  width: 100%;
}
.cart_header {
  padding: 10px 0;
}
.right-pannel {
  overflow-y: auto;
}
.mobile_menu > ul > li > a {
  color: #000;
  font-size: 13px;
}
.mobile_menu > ul > li {
  padding: 8px 0;
}
.humgbugger_menu,
.menu_cart {
  display: none;
}

.menu_cart {
  background: rgba(0, 0, 0, 0.5);
}
.cartDrawer {
  padding: 0;
  text-align: left;
  margin: 0;
  position: fixed;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 9999;
  pointer-events: none;
  transition: 0.5s all;
  visibility: hidden;
  height: 100vh;
  transform: translate(100%);
  box-shadow: 30px 50px 100px #85888c;
  top: 0;
  background: #fff;
  width: 430px;
}
.cartOpen {
  pointer-events: fill;
  transform: translate(0);
  visibility: visible;
  display: block;
}
.Drawerbody {
  padding: 10px 20px;
  margin-top: 0;
}
.Drawerbody .cross_icon {
  margin: 0;
  display: flex;
  justify-content: end;
  align-items: end;
  width: 100%;
}
.cross_icon svg {
  width: 1em;
  height: 1em;
}
.cart-title-detail {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid #000;
  text-transform: uppercase;
}
.cart-title-detail .cart-title h4 {
  font-size: 30px;
  color: #000;
  font-weight: 700;
}
.cart-right-image {
  margin-left: 20px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1d0d0d69;
  z-index: 999;
  display: none;
}
.menuAction img {
  width: 25px;
  height: 25px;
}
@media (min-width: 992px) {
  .headerLogoMenu > div > img {
    max-width: 62px;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .link {
    margin: 0.5rem;
  }
  .link {
    font-size: 13px;
  }
  .headerOuter {
    /* padding: 20px 30px;
    margin: 20px 30px; */
  }
}
@media (max-width: 991px) {
 
  .headerLogoMenu > div > img {
    max-width: 50px;
  }
  .menuAction {
    margin: 3px;
  }
  .menuAction img {
    width: 17px;
    height: 17px;
  }
  .cartDrawer {
    width: 400px;
  }
  .headerMenu {
    display: none;
  }
  .hamburger > div span:nth-child(2) {
    opacity: 0;
  }
  .hamburger > div span:nth-child(1) {
    transform: translateY(4px) rotate(45deg);
  }
  .hamburger > div span:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
  }

  .cart_body {
    background: #f6f6f6;
    max-width: 991px;
    border-radius: 15px;
    margin: 5px 20px;
  }
  .humgbugger_menu > div > span {
    border-radius: 2px;
    transition: all 0.55s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 22px;
    height: 2px;
    background-color: #f5f5fa;
    display: block;
    margin: 5px 0 2px;
  }
  .humgbugger_menu.span span:nth-child(2) {
    opacity: 0;
  }
  .humgbugger_menu,
  .menu_cart {
    display: block;
  }
  .search_tab,
  .profile_tab {
    display: none;
  }
  .headerMenu .desktop_menu {
    display: none;
  }

  .mobile {
    display: block;
  }
  .profile_wrapper {
    text-align: center;
  }
  .profile_image {
    width: 98px;
    height: 98px;
    background: linear-gradient(
      45deg,
      rgb(198, 120, 244) 0%,
      rgb(255, 85, 188) 50%,
      rgb(255, 130, 16) 100%
    );
    border-radius: 50%;
    padding: 5px;
    overflow: hidden;
    margin: 0 auto;
  }
  .menu_cart {
    padding: 0;
    text-align: left;
    margin: 0;
    position: fixed;
    left: 0;
    bottom: 0;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 9999;
    pointer-events: none;
    transition: 0.5s all;
    visibility: hidden;
    width: 100%;
  }
  .menu_cart.addMenu {
    pointer-events: fill;
    transform: translate(0);
    visibility: visible;
    overflow: auto;
    height: 200px;
  }
  .headerOuter {
    padding: 6px 20px;
  }
}
.popWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  outline: 0;
  background: #929292a6;
  z-index: 99;
  display: none;
}
.openPop {
  display: block;
}
.LoginForm {
  margin: 0 auto;
  max-width: 350px;
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  padding: 30px 20px;
}
.logoLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.img_cancel {
  position: absolute;
  right: 0;
}
.LoginForm h5 {
  font-weight: 700;
  font-size: 14px;
  color: #000;
}
.number_text {
  font-weight: 700;
  font-size: 14px;
  color: #a9a7a7;
  margin: 10px 0;
}
.input-box-detail {
  position: relative;
  margin: 15px 0;
}
.code-input input {
  position: absolute;
  height: 25px;
  top: 8px;
  left: 15px;
  width: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.number_input {
  font-weight: 700;
  font-size: 13px;
  color: #333;
  border: unset;
  background: transparent;
  padding: 0 10px 0 60px;
  display: flex;
  border: 1px solid #000000;
  border-radius: 40px;
}
.input-box-detail input {
  line-height: 38px;
  outline: none;
  width: 100%;
  font-weight: 700;
  font-size: 13px;
  color: #333;
  border: unset;
  background: transparent;
  display: flex;
  border-radius: 40px;
}
.img_cancel img {
  cursor: pointer;
  width: 20px;
}
.code-input {
  font-weight: 700;
  font-size: 13px;
  color: #000;
  border: unset;
  background: transparent;
  text-align: center;
  border-right: 2px solid #dcdada;
  height: 40px !important;
  padding: 0 5px;
  width: 50px;
  position: absolute;
}
.submitBtn > button {
  background: #000;
  color: #fff;
  border: 1px solid #000;
  height: 40px;
  width: 100%;
  border-radius: 20px;
  font-weight: 600;
  font-size: 15px;
}
.img_cart img {
  width: 70px;
}
.mobLogo{
 display: none;
}
@media (max-width: 767px) {
  .deskLogo{
    display: none;
  }
  .mobLogo{
    display: block;
   }
   .social_outer{
    display: block;
   }
 .header{
  position: absolute;
  top: 0;
  /* z-index: 99; */
  width: 100%;
 }
  .headerOuter{
    background:transparent;
    box-shadow: none;
    padding:10px;
    position: relative;
    z-index: 9;
    width:78%;
  }
  .menuAction{
    background: linear-gradient(76.19deg, #C678F4 13%, #FF55BC 56.13%, #FF8210 91.59%);
    height:30px;
    width:30px;
    border-radius: 50%;
  }
  .headerComponents{
    background: transparent;
  }
  .menuAction img{
    height: 15px;
    width: 15px;
  }
}

@media (max-width: 420px) {
  .cartDrawer {
    width: 100%;
  }
}

