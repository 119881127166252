.CartItem {
  display: flex;
  gap: 10px;
  margin: 10px 0 5px;
  align-items: center;
  justify-content: space-between;
}
.cartItem_image > img {
  width: 90px;
  height: 90px;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 5px;
}
.CartItem__Title {
  font-size: 15px;
}
a.checkoutBtn{
  text-decoration: none;
    color: #fff;
}
.CartItem__Info {
  display: flex;
  padding-left: 0;
  align-items: center;
  gap: 14px;
}
.CartItem__Actions {
  display: flex;
  margin-top: 0;
  justify-content: center;
  min-width: 112px;
  flex-direction: column;
  text-align: center;
}
.DeleteItem {
  display: flex;
  align-items: center;
}
.QuantitySelector .Icon--minus,
.QuantitySelector .Icon--plus {
  width: 10px;
  height: 10px;
  stroke-width: 1.5px;
  vertical-align: -1px;
}
.CartPriceHeading {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.5);
  margin-top: 30px;
  padding: 10px 15px;
  border-radius: 4px;
}
.CartHD,
.cart-price-heading {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}
.cart-price-text {
  font-size: 14px;
  color: #000;
  text-transform: capitalize;
  font-weight: 400;
}
.cart-price-text,
.cart-price-heading {
  margin: 0;
  line-height: 30px;
}
.CartRightDetails {
  text-align: end;
}
.CartCheckoutButton button {
  width: 100%;
  height: 40px;
  text-transform: uppercase;
  border-radius: 8px;
  border: transparent;
  background: linear-gradient(
    270deg,
    rgb(255, 130, 16) 0%,
    rgb(255, 85, 188) 50%,
    rgb(198, 120, 244) 100%
  );
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}
.CartSubTotal {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
.qtyPrice {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}

.qtyContent {
  white-space: nowrap;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CartItem__Price .PriceContent {
  display: block;
}
.qtyContent input {
  /* width: 50px; */
  /* display: none; */
  text-align: center;
  border: 0;
  background: #fff;
}

.qtyContent span {
  padding: 7px 10px;
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qtyContent {
  border-radius: 6px;
}
.qtyContent span {
  padding: 3px 10px;
}

.qtyContent > div {
  font-size: 13px;
  width: 20px;
}

.CartItem__Price .PriceContent span {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 4px;
}
/* max-width(991px} */
/* cart css */


.offerContainer{
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  align-items: center;
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 10px;
  margin: 1rem 0rem;
}
.offerTextContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}
.offerTextContainer p.offerText{
font-weight: 700;
margin-left: 2rem;
}
.backToCartButtonContainer{
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0.5rem 0;
}
.backToCartButton{
  border: none;
  background: none;
  display: flex;
}
.backToCartText{
font-weight: 700;
margin-left: 0.3rem;
}
.couponsContainerWrapper{
  position: absolute;
  top: 0%;
  background: #f5f5f5;
  height: 100vh;
  left: 0%;
  padding: 1rem;
}
.couponContainer{
  position: relative;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: start;
  border-radius: 7px;
  margin: 1rem 0.1rem;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
  overflow: hidden;
}
.couponInner{
  display: flex;
}
.couponContent .couponDescription{
  color: #000;
  font-weight: lighter;
  margin-top: 5px;
  font-size: 11px;
  padding: 0.5rem 0;
}
.removeCouponWrapper{
  display: flex;
    justify-content: end;
    align-items: center;
}
.removeCouponButton{
  background: var(--key-ing, linear-gradient(32deg, #C678F4 0%, #FF55BC 46.88%, #FF8210 85.42%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* padding: 5px; */
    font-weight: 700;
    /* font-size: 16px; */
    border-radius: 5px;
    border: none;
}
.applyCouponSpinerOverlay{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  z-index: 2;
  opacity: 0.4;
}
.applyCouponSpiner{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.appliedText{
  color: #000;
    font-size: 15px;
    font-weight: 600;
    padding: 6px;
    border-radius: 7px;
    margin-left: 50px;
    text-transform: uppercase;
    background-color: azure;
}
.couponContent .couponDetails{
  color: #000;
  font-weight: lighter;
  font-weight: 400;
  margin-top: 5px;
  font-size: 11px;
}
.couponContent, .ApplyCouponContainer{
  padding: 0.6rem 1.5rem;
}
/* .couponCode{
  background: linear-gradient(32deg, rgba(198, 120, 244, .5) 0%, rgba(255, 85, 188, .5) 46.88%, rgba(255, 130, 16, .5) 85.42%);
  padding: 5px;
  color: #000;
  font-weight: 700;
  font-size: 12px;
  border-radius: 5px;
  width: fit-content;
} */
.couponCode{
  /* background: linear-gradient(32deg, rgba(198, 120, 244, .5) 0%, rgba(255, 85, 188, .5) 46.88%, rgba(255, 130, 16, .5) 85.42%); */
  background: var(--key-ing, linear-gradient(32deg, #C678F4 0%, #FF55BC 46.88%, #FF8210 85.42%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 5px;
  font-weight: 700;
  font-size: 16px;
  border-radius: 5px;
  width: fit-content;

}
.applyCouponButton{
  background:none;
  font-size: 15px;
  font-weight: 700;
  border: none;
  color: #000;
  cursor: pointer;
}
/* .applyCouponButton{
  background: var(--key-ing, linear-gradient(32deg, #C678F4 0%, #FF55BC 46.88%, #FF8210 85.42%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 15px;
  font-weight: 700;
  border: none;
} */

    .leftHalfCircle {
      position: absolute;
      top: 50%;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      background-color: #f5f5f5;
      transform: translate(0%, -50%);
      left: -7%;
    }

    .rightHalfCircle {
      position: absolute;
      top: 50%;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      background-color: #f5f5f5;
      transform: translate(0%, -50%);
      right: -7%;
    }

    .couponStarWrapper{
      position: relative;
    }
.couponStarFirst{
  position: absolute;
  bottom: 18%;
  right: 18%;
  
}
.couponStarSecond{
  position: absolute;
  bottom: -5%;
  right: -1%;
  
}
.couponStarThird{
  position: absolute;
  bottom: -12%;
  transform: rotate(31deg);
}
@media only screen and (max-width: 767.98px) {
  .cart-title-detail {
    padding-bottom: 10px;
  }
  .CartPriceHeading {
    margin-top: 10px;
  }
  .CartSubTotal{
    padding: 10px 0;
  }
  .cart-title-detail .cart-title h4 {
    font-size: 20px;
  }
  .cart-title-detail .cart-title span {
    font-size: 12px;
  }
  .cartItem_image > img {
    width: 50px;
    height: 50px;
  }
  .CartItem__Title {
    font-size: 11px;
  }
  .qtyContent {
    height: 28px;
  }
  .CartHD,
  .cart-price-heading {
    font-size: 13px;
  }
  .cart-price-text,
  .cart-price-heading {
    line-height: 25px;
  }
  .cart-price-text {
    font-size: 12px;
  }
  .CartCheckoutButton button {
    font-size: 14px;
  }
  .qtyContent {
    width: 70%;
  }
  .CartItem__Price .PriceContent span {
    font-size: 13px;
  }
}
