@font-face {
  font-family: "RedHatText";
  src: local("RedHatText"),
    url("./fonts/RedHatText-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "HarmoniaSansProCyr";
  src: local("HarmoniaSansProCyr"),
    url("./fonts/HarmoniaSansProCyr-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  src: local("Pretendard"),
    url("./fonts/HarmoniaSansProCyr-Regular.ttf") format("truetype");
}

.font-RedHat {
  font-family: "RedHatText";
}

.font-Harmonia {
  font-family: "HarmoniaSansProCyr";
}

.font-Pretendard {
  font-family: "Pretendard";
}
.font-filter {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

body {
  letter-spacing: 1px;
  
}
.desktopview{
  background-color: #F6F6F6;
}
.desktop_view{
  width: 70%;
  margin: 0 auto;
  padding: 20px 0px;
  text-align: center;
}
.desktop_view h2{
  font-family: "HarmoniaSansProCyr";
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    text-transform: uppercase;
}
.desktop_view p{
  color: #000;
    text-align: center;
    font-family: "HarmoniaSansProCyr";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    width: 65%;
    margin: 0 auto;
}

.mid_QRCode{
  display: flex;
    justify-content: center;
    align-items: center;
    /* position:relative; */
    width: 100%;
    padding: 20px 0;
}
.qrcodeImg{
  /* /* width: 150px;
  position: absolute;
 
  left: 21%;
  top: 35%; */
  /* height: 150px; */
  padding: 20px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 8px 8px 16px 0px rgba(188, 187, 187, 0.06), -8px -8px 16px 0px rgba(167, 167, 167, 0.06);
  /* display: flex;
  justify-content: center;
  align-items: center; */ 

}
.qrcodeImg img{
  width:100%;
}
.vtoImg{
  width: 30%;
  /* position: relative; */
  /* z-index: 9; */
  left: 10%;
}
.filter_name {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  min-height: 17px;
  color: #282c3f;
}
.smallNav{
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
}
.navItems{
  /* display: flex; */
  display:none;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0px;
    width: 38%;
    margin: 0 auto;
}
.navlink_makeup{
  border-left: 1px solid gray;
  border-right: 1px solid gray;
}
.navlink_makeup a{
  color:#FF55BC;
}
li.navlinks:first-child a{
  color: #C678F4;
}
li.navlinks:last-child a{
  color: #FF8210  ;
}
li.navlinks a{
  text-decoration: unset;
}
li.navlinks{
  /* font-family: 'RedHatText'; */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.32px;
  text-transform: uppercase;
  padding: 0 45px;
}

.filter_name input[type="radio"] {
  visibility: hidden;
}

.filter_name input[type="radio"] {
  width: 10px;
  height: 10px;
  vertical-align: middle;
  background-color: rgba(235, 232, 232, 1);
  appearance: none;
}

.boxButton {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  border: 1px solid rgb(255 85 188);
  border-radius: 50%;
}

.filter_name>input:checked~.boxButton::after {
  border: none;
  background: rgba(255, 85, 188, 1);
  display: block;

}

.boxButton::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.CartDetailsC{
  height: auto;
  overflow-y: scroll;
}
@media (max-width: 420px) {
  .filter_name {
    font-size: 7px;
}
.cartDrawer {
  width: 100%;
}
}