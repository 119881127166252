/* filter css */
.filter_mob button {
  padding: 5px 20px;
  background: #fff;
  border: 1px solid #ff55bc;
  border-radius: 5px;
  font-weight: 600;
  color: #ff55bc;
}
.upperContent > div{
  height:150px;
}
.filterWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  background: rgb(0 0 0 / 49%);
  width: 100%;
  z-index: 99;
  transition: 0.5s all;
  transform: translateY(100%);
  transition: transform 0.3s ease;
}


.filterOuter {
  position: relative;
  height: 100%;
}
.atcBtns_wrraper{
  width: 75%;
}
.crossbtn {
  position: absolute;
  right: 5%;
  top: 3%;
  transform: translate(0);
  background: none;
  padding: 0;
  border: 0;
}

.filterInner {
  position: absolute;
  border-radius: 25px 25px 0 0;
  top: 60px;
  background: rgba(235, 232, 232, 1);
  /* background: green; */
  left: 0;
  right: 0;
  overflow: hidden;
  height:95%;
}



.similarInner{
  /* position: absolute;
  border-radius: 25px 25px 0 0;
  top: 60px;
  background: rgba(235, 232, 232, 1); */
  /* background: green; */
  /* left: 0;
  right: 0;
  overflow: hidden; */
  transform: translateY(0%);
  bottom:0%;

  /* transition: 0.3s ease-in-out;
  height: calc(100% - 60px);
  margin:0 */
}

.abc{
position: absolute;
  border-radius: 25px 25px 0 0;
  /* bottom: 45%; */
  background: rgba(235, 232, 232, 1);
  /* background: green; */
  left: 0;
  right: 0;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  height: calc(100% - 60px);
  margin:0
}


.XsimilarInner{
  /* background: rgba(235, 232, 232, 1);
  border-radius: 25px 25px 0 0;
 height: 300px;
 overflow: hidden;
  transform: translateY(100%);
  transition: 0.3s ease-in-out; */
  bottom: 40%;

 height: 300px;


  transform: translateY(200%);
 

}
.filterOuterContainer{
  position:"relative";
  z-index:99
  /* margin-top:-300px */
}


.filterOuter{}

.filterOuter .Icon--close {
  width: 15px;
  color: #fff;
}
.filter_item{
  height:76vh;
  overflow-y:scroll;
}
.searchFilters {
  border-bottom: 1px solid #eaeaec;
  text-align: center;
  margin: 0;
}

.searchFilters h2 {
  padding: 14px 12px 10px 20px;
  text-align: left;
  font-weight: 600;
  font-size: 17px;
}

.Filters-Content {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 10px 15px;
}

.filter_left {
  width: 40%;
}

.filter_right {
  width: 60%;
  padding: 0 15px;
}

.ripple-container {
  padding: 8px 10px;
  text-transform: capitalize;
  font-size: 13px;
}

.ripple-container.checkInput {
  background: linear-gradient(
    76.19deg,
    #c678f4 13%,
    #ff55bc 56.13%,
    #ff8210 91.59%
  );
  color: #fff;
  border-radius: 25px, 25px, 0px, 0px;

}

.divider_line {
  background: rgba(214, 214, 214, 1);
  height: 100vh;
  width: 1px;
}

.footerFilter {
  position: fixed;
  bottom: 0;
  display: flex;
  text-align: center;
  align-items: center;
  z-index: 9999;
  width: 100%;
  border-top: 1px solid #ddd;
  width: 100%;
  background-color: #fff;
}

.footerFilter button {
  border: 0;
  background: #fff;
  height: 36px;
  width: 100%;
}

.filterTitle {
  font-size: 13px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.03em;
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  background-color: rgba(235, 232, 232, 1);
}

.filterWrapper.filterOpen {
  transition: 0.5s all;
  transform: translateY(0%);
  transition: transform 0.7s ease;
}
.filterWrapper{
    transition: 0.5s all;

}

.transition{
  transform: translateY(50%);
  /* transition: transform 2s ease; */
}


/* .productwrapper{
 transition: 0.5s all;
  transform: translateY(3%);
  transition: transform 0.5s ease;
} */

.filterTitle input[type="checkbox"] {
  visibility: hidden;
}
.filterTitle input[type="radio"] {
  visibility: hidden;
}
.label_Brand {
  position: relative;
  display: block;
  width: 95%;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 17px;
  color: #282c3f;
}

.common-checkboxIndicator {
  position: absolute;
  /* top: 8px; */
  top: 4px;
  left: 0;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  border: 1px solid rgb(192 192 192);
}

.label_Brand > input:checked ~ .common-checkboxIndicator {
  border: none;
  background: linear-gradient(
    76.19deg,
    #c678f4 13%,
    #ff55bc 56.13%,
    #ff8210 91.59%
  );

  display: block;
}

.common-checkboxIndicator:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 3px;
  width: 7px;
  height: 2px;
  transition: all 0.3s ease-in-out;
  transform: rotate(-45deg);
  border-style: none none solid solid;
  border-width: 2px;
  border-color: #fff;
  display: none;
}

.label_Brand input {
  margin: 0 16px 0 0;
}

.label_Brand input:checked ~ .common-checkboxIndicator:after {
  display: block;
}

.filter_option {
  display: none;
}

.filterActive {
  display: block;
}

.close_btn {
  border-right: 1px solid #ddd;
}

.footerApply,
.close_btn {
  width: 50%;
}

.resultActive {
  display: block;
  padding: 0 5px;
  height: 100vh;
  overflow: scroll;
}

.card_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap:3px;
  width: 100%;
  /* flex-wrap: wrap; */
  align-items: flex-start;
  /* justify-content: space-between; */
  margin-bottom: 8rem;
}

.cardContainer {
  width: 90%;
  padding: 0.5rem 0.3rem;
  box-shadow: 4px 4px 4px -4px rgba(0, 0, 0, 0.1),
    -4px -4px 4px -4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #fff;
  /* flex: 0 0 43%; */
  min-height: 18rem;
  height: fit-content; /* Add this to fit content height */
}


.m0 {
  margin: 0;
}

.cardContainer {
  margin-left: 5px;
  margin-bottom: 10px;
}

.productImg{
  width:100%;
  height: 100%;
  min-height:150px;
}
.middleContent {
  display: flex;
  padding-top: 10px;
}

.productDetail, .productPrice {
  width: 50%;
}
.productPrice {
  text-align: right;
}

.productName {
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  color: #000;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.shades {
  text-align: center;
  color: #ff55bc;
  font-size: 11px;
  font-weight: 600;
  padding: 10px 0;
}

.bottomContent {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.favBtn {
  border-radius: 5px;
  border: 1px solid #ff55bc;
  padding: 4px;
  display: flex;
  align-items: center;
  background: none;
}

.favBtn svg {
  height: 20px;
  width: 20px;
}

.atcAction {
  background: #ff55bc;
  padding: 5px 10px;
  border: 1px solid #ff55bc;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  height: 100%;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  font-weight: 700;
  position: relative;
}

.cardTitle {
  text-align: center;
  font-size: 13px;
  color: #000;
  font-weight: 600;
  text-transform: capitalize;
}

.productSize {
  font-size: 10px;
  color: #ff55bc;
  max-height: 25px;
}

.actualPrice {
  font-size: 12px;
  font-weight: 600;
}

.comparePrice {
  font-size: 10px;
  text-decoration: line-through;
  color: #b1aeae;
  font-weight: 600;
}

.discount {
  color: #2aa000;
  font-size: 10px;
  font-weight: 600;
}

.dflex {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #bdbabaa8;
  z-index: 99;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ATC_button {
  padding-right: 5px;
}
.ATC_button_action {
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  justify-content: center;
}
.productLoader {
  position: absolute;
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #ff55bc;
  width: 10px;
  height: 10px;
  animation: spin 2s linear infinite;
  top: 30%;
  left: 50%;
  transform: translate(-50%);
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.hidden {
  visibility: hidden;
}
@media only screen and (max-width: 767.98px) {
  .filterTitle {
    font-size: 12px;
    line-height: 26px;
}
}
