.footerComponents {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main_wrapper {
  color: #fff;
  /* width: 100%; */
  padding: 4rem 1rem;
  display: flex;
  border-radius: 30px;
  background: var(
    --nav-bar-colour,
    linear-gradient(90deg, #c678f4 1.64%, #ff55bc 50.99%, #ff8210 100%)
  );
}
.main-Container a {
  text-decoration: none;
  color: #fff;
}
.innerwrapper {
  padding: 0 20px;
  text-align: left;
  margin: 0 10px;
  letter-spacing: 1.2px;
}
.innerwrapper.w-25 {
  width: 20%;
}
.innerwrapper.w-20 {
  width: 20%;
}
.innerwrapper.w-35 {
  width: 45%;
}

.innerwrapper h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 103.5%;
}
.innerwrapper p,
.innerwrapper h2 {
  text-transform: uppercase;
}
.logo_img img {
  width: 196.998px;
  height: 156.048px;
}
.innerwrapper nav {
  margin-top: 15px;
}
.main-Text {
  font-size: 12px;
}
.innerwrapper nav > a {
  font-size: 15px;
  margin-bottom: 15px;
  display: block;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
}
nav > p {
  font-weight: 500;
  color: #fff;
  text-decoration: none;
}
.social_wrapper {
  margin-top: 35px;
  margin-left: 15px;
}
.social_icons {
  margin-top: 15px;
}
.social_icons img {
  margin-right: 6px;
}
.input_text {
  padding: 30px 0;
}
.input_text input::placeholder {
  color: #fff;
  text-transform: uppercase;
  padding-left: 30px;
}
.input_text input {
  width: 100%;
  height: 55px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 4px;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 700;
  background-color: #ef9a9a0d;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNNTA4LjkgNDc4LjdMMzYwLjEgMzMwYzI4LjItMzQuOCA0NS4yLTc5LjEgNDUuMi0xMjcuM0M0MDUuMyA5MC45IDMxNC40IDAgMjAyLjcgMFMwIDkwLjkgMCAyMDIuN3M5MC45IDIwMi43IDIwMi43IDIwMi43YzQ4LjIgMCA5Mi41LTE3IDEyNy4zLTQ1LjJsMTQ4LjcgMTQ4LjdjNC4yIDQuMiAxMC45IDQuMiAxNS4xIDBsMTUuMS0xNS4xYzQuMS00LjIgNC4xLTEwLjkgMC0xNS4xem0tMzA2LjItMTE2Yy04OC4yIDAtMTYwLTcxLjgtMTYwLTE2MHM3MS44LTE2MCAxNjAtMTYwIDE2MCA3MS44IDE2MCAxNjAtNzEuOCAxNjAtMTYwIDE2MHoiIGZpbGw9IiMzNDNmNDkiLz48L3N2Zz4=) !important;
  background-size: 15px !important;
  background-position-x: calc(100% - 12px) !important;
  background-position-y: center !important;
  background-repeat: no-repeat !important;
  color: #000;
  line-height: normal;
  border-radius: 77px;
}
.textPara {
  font-weight: 600;
}
@media (max-width: 992px) {
  .main_wrapper {
    padding: 4em 20px;
  }
  nav > a {
    font-size: 12px;
  }
  .logo_img img {
    width: 150px;
    height: 150px;
  }
  .social_wrapper {
    margin-top: 10px;
    margin-left: 10px;
  }
  .social_icons img {
    margin-right: 0px;
  }
  .innerwrapper h2 {
    font-size: 20px;
  }
  .input_text input {
    height: 40px;
  }
  .input_text input::placeholder {
    padding-left: 20px;
    font-size: 10px;
    font-weight: 400;
  }

  .textPara {
    font-size: 13px;
  }
  .social_icons {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .main_wrapper {
    padding: 2em 15px;
    flex-direction: column;
  }
  .innerwrapper.w-25 {
    width: 100%;
    order: -1;
  }
  .innerwrapper {
    text-align: center;
    padding: 0;
    margin: 0;
    letter-spacing: 1.2px;
  }
  .innerwrapper.w-35 {
    width: 100%;
    order: -1;
    margin: 10px 0;
  }
  .innerwrapper.w-20 {
    width: 50%;
    order: 2;
    text-align: left;
  }
  .main_wrapper {
    flex-wrap: wrap;
    flex-direction: unset;
  }
  .input_text {
    padding: 20px 0;
  }
  .innerwrapper h2 {
    font-size: 14px;
  }
  .textPara {
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    line-height: 1.7;
    letter-spacing: 1px;
  }
  .social_icons img {
    margin-right: 10px;
    width: 18px;
    height: 18px;
  }
  .menuFooter .main-Text {
    font-size: 8px;
    font-weight: 400;
    opacity: 0.7;
  }
  .menuFooter > nav {
    margin-top: 15px;
  }
  .menuFooter > nav > a {
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 8px;
  }
}
